import { Component, ViewChild, ElementRef, HostListener, OnInit, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations'
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { Destinations } from '../models/index';
import { HomeService, DataService, MixpanelService } from '../services/index';
import { formatDate } from '@angular/common';
import { Router } from "@angular/router";
import { SharedscrollstatusService } from '../services/sharedscrollstatus.service';
import { TownsService } from '../services/towns.service';
import { Base64 } from 'js-base64';
import { PixelService } from '../pixel.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(-100%)'}),
        animate('2000ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('2000ms ease-in', style({transform: 'translateY(-100%)'}))
      ])
    ])
  ]
})
export class HomeComponent implements OnInit {
  bsInlineValue = new Date();
  reactiveForm: UntypedFormGroup;
  fromPlaceholder = "From";
  toPlaceholder = "To";
  destinationLoading = false;
  pickup = '';
  dropoff = '';
  traveldate = '';
  daterange = '';
  destinations: Destinations[] = [];
  destination: any = {};
  res: any = {};
  today = new Date();
  bsValue = new Date();
  public pickplaceholder: string = 'Depature';
  public destplaceholder: string = 'Destination';
  public historyHeading: string = 'Recently selected';
  keyword = 'name';
  isClicked = false;
  isPickupClicked = false;
  returning = false;
  isReadOnly = false;
  types = [];
  data = [];
  msg = '';
  formdata = {};
  submitted = false;
  loading: boolean;
  currentVersion: boolean = false;
  currentVnumber;
  storedVnumber;
  form = new UntypedFormGroup({
    traveldate: new UntypedFormControl(new Date()),
  });

  defaultImage = "assets/img/cities/placeholder.jpg";
  mombasa = "assets/img/cities/mombasa.jpg";
  nairobi = "assets/img/cities/nairobi.jpg";
  kisumu = "assets/img/cities/kisumu.jpg";
  kampala = "assets/img/cities/kampala.jpg";
  dar = "assets/img/cities/dar.jpg";
  bungoma = "assets/img/cities/bungoma.jpg";
  eldoret = "assets/img/cities/eldoret.jpg";

  @ViewChild('stickyForm') menuElement: ElementRef;
  sticky: boolean = false;
  isOpen = false;
  elementPosition: any;

  constructor(
    private service: HomeService,
    private dataService: DataService,
    private router: Router,
    private _fb: UntypedFormBuilder,
    private townService: TownsService,
    private serviceScroll: SharedscrollstatusService,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private mixpanelService: MixpanelService,
    private pixel: PixelService 
  ) {
    this.reactiveForm = _fb.group({
      pickup: [null, Validators.required],
      dropoff: [null, Validators.required],
      daterange: [formatDate(new Date(), "yyyy-MM-dd", "en") + ':One Way', Validators.required],
      traveldate: [new Date(), Validators.required],
      returndate: [null, null]
    });
  }

  ngOnInit() {
    let message = sessionStorage.getItem('bversion');
    if (message) {
      this.storedVnumber = message;
      this.getCurrentVesrion();
    } else {
      this.storedVnumber = "0.0.0";
      this.getCurrentVesrion();
    }

    localStorage.setItem('search', '');
    this.loadDestinations();
    this.formControlValueChanged();
    this.cdRef.detectChanges();
    let top = document.getElementById('tophome');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }

      this.mixpanelService.track('PageView', {
        pageName: 'Home page',
        source: 'buupassSite',
        role: 'customer',
      });

      this.loadDesktopScript();
      this.loadAds();

  }

  onTravelDateChange = (event) => {
    var traveldate = formatDate(event, "yyyy-MM-dd", "en-KE");
    this.today = event;
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll(event) {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= this.elementPosition) {
      this.menuElement.nativeElement.setAttribute('style', 'position:fixed');
      this.sticky = true;
    } else {
      this.sticky = false;
    }
    this.serviceScroll.changeValue(this.sticky);
  }

  get f() {
    return this.reactiveForm.controls;
  }

  formControlValueChanged() {
    const pickupControl = this.reactiveForm.get('pickup');
    const dropoffControl = this.reactiveForm.get('dropoff');
  
    if (pickupControl) {
      pickupControl.valueChanges.subscribe((mode: string) => {
        // this.serviceDepature.changeValue(mode);
      });
    }
  
    if (dropoffControl) {
      dropoffControl.valueChanges.subscribe((mode: string) => {
        // this.serviceDestination.changeValue(mode);
      });
    }
  }

  onFocused(e) {
    this.isClicked = true;
  }

  returnClicked = () => {
    this.returning = true;
    this.isOpen = true;
  }

  closeReturn() {
    this.returning = false;
  }

  onClosed(e) {
    this.isClicked = false;
  }

  onPickupFocused(e) {
    this.isClicked = true;
    this.isPickupClicked = true;
  }

  onPickupClosed(e) {
    this.isClicked = false;
    this.isPickupClicked = false;
  }

  // Updated submitForm to update the URL
  submitForm() {
    if (this.reactiveForm.value.returndate == null) {
      this.returning = false;
      this.isOpen = false;
    }
    this.submitted = true;
  
    // Stop here if the form is invalid
    if (this.reactiveForm.invalid) {
      return;
    }
  
    let value = this.reactiveForm.value;
    var traveldate = formatDate(value.traveldate, "yyyy-MM-dd", "en-KE");
    var returndate = value.returndate ? formatDate(value.returndate, "yyyy-MM-dd", "en-KE") : null;
  
    value.traveldate = traveldate;
    if (this.returning) {
      value.returndate = returndate;
    }
  
    this.formdata = {
      pickup: value.pickup,
      dropoff: value.dropoff,
      pickupname: value.pickup,
      dropoffname: value.dropoff,
      traveldate: traveldate,
      returndate: returndate,
      returning: this.returning
    };
  
    // Tracking event with Mixpanel and Pixel
    this.mixpanelService.track('BusSearch', {
      pickup: value.pickup,
      dropoff: value.dropoff,
      traveldate: traveldate,
      source: 'buupassSite',
      role: 'customer',
    });
  
    this.pixel.trackCustom('BusSearch', {
      platform: 'web',
      type: "ButtonClick",
      pickup: value.pickup,
      dropoff: value.dropoff,
      traveldate: this.today,
    });
  
    let p = Base64.encode(JSON.stringify(this.formdata));
    this.dataService.changeSearchMessage(p);
  
    // Navigate using slashes for route parameters
    this.router.navigate([`/booking/${value.pickup}/${value.dropoff}/${traveldate}`]);
  }
  
  popularRoutes = [
    { from: 'Nairobi', to: 'Mombasa' },
    { from: 'Nairobi', to: 'Kisumu' },
    { from: 'Nairobi', to: 'Eldoret' },
    { from: 'Mombasa', to: 'Nairobi' },
    { from: 'Kisumu', to: 'Nairobi' },
    { from: 'Eldoret', to: 'Nairobi' },
    // Add more routes as necessary
  ];



  nairobiKisumu() {
    let kisumu = {
      'pickup': 'Nairobi',
      'dropoff': 'Kisumu',
      'pickupname': 'Nairobi',
      'dropoffname': 'Kisumu',
      'traveldate': this.today,
      'returndate': '',
      'returning': this.returning
    };

    this.mixpanelService.track('BusSearch', {
      pickup: "Nairobi",
      dropoff: "Kisumu",
      traveldate: this.today,
      source: 'buupassSite',
      role: 'customer',
    });

    this.pixel.trackCustom('BusSearch', {
      platform: 'web',
      type: "TopDestinationClick",
      pickup: "Nairobi",
      dropoff: "Kisumu",
      traveldate: this.today,
    });

    let p = Base64.encode(JSON.stringify(kisumu));
    this.dataService.changeSearchMessage(p);
    this.router.navigate(['/booking']);
  }

  nairobiKitale() {
    let kitale = {
      'pickup': 'Nairobi',
      'dropoff': 'Kitale',
      'pickupname': 'Nairobi',
      'dropoffname': 'Kitale',
      'traveldate': this.today,
      'returndate': '',
      'returning': this.returning
    };

    this.mixpanelService.track('BusSearch', {
      pickup: "Nairobi",
      dropoff: "Kitale",
      traveldate: this.today,
      source: 'buupassSite',
      role: 'customer',
    });

    this.pixel.trackCustom('BusSearch', {
      platform: 'web',
      type: "TopDestinationClick",
      pickup: "Nairobi",
      dropoff: "Kitale",
      traveldate: this.today,
    });

    let p = Base64.encode(JSON.stringify(kitale));
    this.dataService.changeSearchMessage(p);
    this.router.navigate(['/booking']);
  }

  nairobiBungoma() {
    let bungoma = {
      'pickup': 'Nairobi',
      'dropoff': 'Bungoma',
      'pickupname': 'Nairobi',
      'dropoffname': 'Bungoma',
      'traveldate': this.today,
      'returndate': '',
      'returning': this.returning
    };

    this.mixpanelService.track('BusSearch', {
      pickup: "Nairobi",
      dropoff: "Bungoma",
      traveldate: this.today,
      source: 'buupassSite',
      role: 'customer',
    });

    this.pixel.trackCustom('BusSearch', {
      platform: 'web',
      type: "TopDestinationClick",
      pickup: "Nairobi",
      dropoff: "Bungoma",
      traveldate: this.today,
    });

    let p = Base64.encode(JSON.stringify(bungoma));
    this.dataService.changeSearchMessage(p);
    this.router.navigate(['/booking']);
  }

  nairobiBusia() {
    let busia = {
      'pickup': 'Nairobi',
      'dropoff': 'Busia',
      'pickupname': 'Nairobi',
      'dropoffname': 'Busia',
      'traveldate': this.today,
      'returndate': '',
      'returning': this.returning
    };

    this.mixpanelService.track('BusSearch', {
      pickup: "Nairobi",
      dropoff: "Busia",
      traveldate: this.today,
      source: 'buupassSite',
      role: 'customer',
    });

    this.pixel.trackCustom('BusSearch', {
      platform: 'web',
      type: "TopDestinationClick",
      pickup: "Nairobi",
      dropoff: "Busia",
      traveldate: this.today,
    });

    let p = Base64.encode(JSON.stringify(busia));
    this.dataService.changeSearchMessage(p);
    this.router.navigate(['/booking']);
  }

  nairobiMombasa() {
    let mombasa = {
      'pickup': 'Nairobi',
      'dropoff': 'Mombasa',
      'pickupname': 'Nairobi',
      'dropoffname': 'Mombasa',
      'traveldate': this.today,
      'returndate': '',
      'returning': this.returning
    };

    this.mixpanelService.track('BusSearch', {
      pickup: "Nairobi",
      dropoff: "Mombasa",
      traveldate: this.today,
      source: 'buupassSite',
      role: 'customer',
    });

    this.pixel.trackCustom('BusSearch', {
      platform: 'web',
      type: "TopDestinationClick",
      pickup: "Nairobi",
      dropoff: "Mombasa",
      traveldate: this.today,
    });

    let p = Base64.encode(JSON.stringify(mombasa));
    this.dataService.changeSearchMessage(p);
    this.router.navigate(['/booking']);
  }

  nairobiKampala() {
    let kampala = {
      'pickup': 'Nairobi',
      'dropoff': 'Kampala',
      'pickupname': 'Nairobi',
      'dropoffname': 'Kampala',
      'traveldate': this.today,
      'returndate': '',
      'returning': this.returning
    };

    this.pixel.trackCustom('BusSearch', {
      platform: 'web',
      type: "TopDestinationClick",
      pickup: "Nairobi",
      dropoff: "Kampala",
      traveldate: this.today,
    });

    let p = Base64.encode(JSON.stringify(kampala));
    this.dataService.changeSearchMessage(p);
    this.router.navigate(['/booking']);
  }

  private loadDestinations() {
    this.destinationLoading = true;

    this.service.getDestinations().subscribe(
      response => {
        let list: string[] = [];
        this.res = response;
        this.res.data.all_cities.forEach(element => {
          list.push(element.name);
        });
        this.data = list;
        this.townService.changeValue(this.res.data.all_cities);
        let cities = Base64.encode(JSON.stringify(this.res.data.all_cities));
        localStorage.setItem('cities', cities);
        this.types = this.res.data;
        this.destinationLoading = false;
      },
      error => { }
    );
  }

  
  
  private getCurrentVesrion() {
    this.service.getLatestVersion().subscribe(
      response => {
        this.res = response;
        this.currentVnumber = this.res.data.version;
        if (this.currentVnumber != this.storedVnumber) {
          window.location.replace('/');
          sessionStorage.setItem('bversion', this.currentVnumber);
        }
      },
      error => { }
    );
  }


  loadDesktopScript() {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.text = `
      (window.globalAmlAds = window.globalAmlAds || []).push(function() {
          globalAml.defineSlot({z: 'ceeded0d-9d3e-4592-ae7f-b1617c665612', ph: 'yehtu_ceeded0d9d3e4592ae7fb1617c665612_zone_125481_sect_59123_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
          globalAml.defineSlot({z: '537f67f8-0a4c-42b6-9a3c-e526fbebbeaa', ph: 'yehtu_537f67f80a4c42b69a3ce526fbebbeaa_zone_125484_sect_59123_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
          globalAml.defineSlot({z: '7f88d539-e711-4e95-ae2c-f2a96217ceba', ph: 'yehtu_7f88d539e7114e95ae2cf2a96217ceba_zone_125485_sect_59123_site_52307', i: 'inv-nets', s:'86cf3bbc-0621-4cb7-9029-c29dcc2e94ba', sender: 'yehtu'});
          globalAml.singleRequest("yehtu");
      });
    `;
    this.renderer.appendChild(document.body, script);
    
  }

  loadAds() {

    // below search form
    const script1 = this.renderer.createElement('script');
    script1.type = 'text/javascript';
    script1.text = `
      (window.globalAmlAds = window.globalAmlAds || []).push(function() {
          globalAml.display('yehtu_ceeded0d9d3e4592ae7fb1617c665612_zone_125481_sect_59123_site_52307');
      });
    `;
    
    // Append the script for the web view (xmas-img-web)
    const webDiv = document.getElementById('yehtu_ceeded0d9d3e4592ae7fb1617c665612_zone_125481_sect_59123_site_52307');
    if (webDiv) {
      this.renderer.appendChild(webDiv, script1);
    }

    // After the 2.5M text

    const script11 = this.renderer.createElement('script');
    script11.type = 'text/javascript';
    script11.text = `
       (window.globalAmlAds = window.globalAmlAds || []).push(function() {
        globalAml.display('yehtu_537f67f80a4c42b69a3ce526fbebbeaa_zone_125484_sect_59123_site_52307');
    });
    `;
    
    // Append the script for the web view (xmas-img-web)
    const webDiv11 = document.getElementById('yehtu_537f67f80a4c42b69a3ce526fbebbeaa_zone_125484_sect_59123_site_52307');
    if (webDiv11) {
      this.renderer.appendChild(webDiv11, script11);
    }

     // End after the 2.5M text

    const script2 = this.renderer.createElement('script');
    script2.type = 'text/javascript';
    script2.text = `
      (window.globalAmlAds = window.globalAmlAds || []).push(function() {
          globalAml.display('yehtu_ceeded0d9d3e4592ae7fb1617c665612_zone_125481_sect_59123_site_52307');
      });
    `;
    
    // Append the script for the mobile view (xmas-img-mobile)
    const mobileDiv = document.getElementById('yehtu_ceeded0d9d3e4592ae7fb1617c665612_zone_125481_sect_59123_site_52307');
    if (mobileDiv) {
      this.renderer.appendChild(mobileDiv, script2);
    }

    const script22 = this.renderer.createElement('script');
    script22.type = 'text/javascript';
    script22.text = `
      (window.globalAmlAds = window.globalAmlAds || []).push(function() {
                    globalAml.display('yehtu_537f67f80a4c42b69a3ce526fbebbeaa_zone_125484_sect_59123_site_52307');
                });
    `;
    
    // Append the script for the mobile view (xmas-img-mobile)
    const mobileDiv22 = document.getElementById('yehtu_537f67f80a4c42b69a3ce526fbebbeaa_zone_125484_sect_59123_site_52307');
    if (mobileDiv22) {
      this.renderer.appendChild(mobileDiv22, script22);
    }



  }


}
